<template>
  <v-container class="home-container">
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <h1 class="home-text">
          Understanding how to improve cost, scale, and performance of complex cloud environments is hard. It doesn't have to be.
        </h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <v-btn color="primary" @click="dialog = true">Get more info</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Get More Info</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="name"
              label="Name"
              :rules="[v => !!v || 'Name is required']"
              required
            ></v-text-field>
            <v-text-field
              v-model="email"
              label="Email"
              :rules="[v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="sendEmail">Submit</v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'Home',
  data() {
    return {
      dialog: false,
      valid: false,
      name: '',
      email: ''
    };
  },
  methods: {
    sendEmail() {
      if (this.$refs.form.validate()) {
        const serviceID = 'service_gdg1k8c';
        const templateID = 'template_x1zf9jl';
        const userID = '-lJmn4EJXVihzT2tg'; // Updated user ID
        const templateParams = {
          from_name: this.name,
          from_email: this.email
        };

        emailjs.send(serviceID, templateID, templateParams, userID)
            .then(() => {
              alert('Email sent successfully');
              this.dialog = false;
            })
            .catch(() => {
              alert('Failed to send email');
            });
      }
    }
  }
};
</script>

<style scoped>
.home-container {
  margin-top: 20vh;
}

.home-text {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
</style>
