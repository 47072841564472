<template>
  <v-container class="top-padding">
    <v-row>
      <v-col cols="12">
        <h1 class="color2 roboto-font">Enhancing Your Cloud Solutions Practice</h1>
        <p class="spaced-paragraph roboto-font">At Magellan, we understand the challenges cloud partners face in helping customers manage and enhance complex cloud environments. Our tools and expertise are designed to help you deliver actionable insights and solutions that drive business success for your clients.</p>

        <h2 class="color2 roboto-font">Why Magellan?</h2>
        <p class="spaced-paragraph roboto-font">Our platform provides the knowledge and resources you need to help your clients optimize costs, improve scalability, and enhance performance. Using a natural language interface, Magellan enables you to collaborate effectively with your clients to create actionable roadmaps aligned with their business goals. We help you shorten the cycle time between the ask, investigation, and realization of value, ensuring your clients see results faster.</p>

        <h2 class="color2 roboto-font">Benefits Provided</h2>
        <p class="spaced-paragraph roboto-font">Magellan bridges the gap between technical metrics and business objectives, ensuring you deliver measurable results. We help you deliver to your clients:</p>
        <p class="spaced-paragraph roboto-font indented"><strong class="color2">Identify and Deliver Cost-Saving Opportunities beyond the standard reserved instances,</strong></p>
        <p class="spaced-paragraph roboto-font indented"><strong class="color2">Improve End User experience via narrowly targeted engineering enhancements</strong></p>
        <p class="spaced-paragraph roboto-font indented"><strong class="color2">Provide actionable plans to support user growth</strong></p>
        <p class="spaced-paragraph roboto-font indented"><strong class="color2">Build roadmaps that work both technically and meet organization constraints</strong> </p>

        <h2 class="color2 roboto-font">Driving Customer Wins</h2>
        <p class="spaced-paragraph roboto-font">Magellan is dedicated to helping you drive customer wins and build a history of trust with your clients. Our platform enables you to rapidly deliver business value and achieve a strong return on investment (ROI). Magellan helps your teams become trusted advisors to your customers with an improved ability to rapidly understand their challenges and deliver tangible results back to them.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CloudAdvisorsPage'
}
</script>

<style scoped>
.top-padding {
  padding-top: 100px;
}

.color2 {
  color: #ff9900;
}

.roboto-font {
  font-family: 'Roboto', sans-serif;
}

.spaced-paragraph {
  margin-bottom: 20px;
}

.indented {
  margin-left: 20px;
}

.bold-text {
  font-weight: bold;
  color: #ff9900;
}
</style>
