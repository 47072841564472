<template>
  <v-app-bar app color="#ffffff" height="80px">
    <v-toolbar-title>
      <router-link to="/">
        <img src="@/assets/logo-horizontal.png" alt="Logo" class="logo-image">
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{ props }">
        <v-btn text v-bind="props" class="color2">Solutions</v-btn>
      </template>
      <v-list>
        <v-list-item :to="{ name: 'EndUsersPage' }">
          <v-list-item-title>Engineering Teams</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'CloudAdvisorsPage' }">
          <v-list-item-title>Cloud Partners</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'FinOpsPage' }">
          <v-list-item-title>FinOps</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn text :to="{ name: 'OpensourcePage' }" class="color2">Opensource</v-btn>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar'
}
</script>

<style scoped>
.v-toolbar-title {
  font-family: 'Righteous', sans-serif;
}

.v-btn {
  font-family: 'Righteous', sans-serif;
}

.logo-image {
  height: 80px;
}
</style>
