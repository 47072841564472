<template>
  <v-container class="top-padding">
    <v-row>
      <v-col cols="12">
        <h1 class="color2 roboto-font">Magellan for SREs and Technical Teams</h1>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Better, Faster, Cheaper</strong></p>
        <p class="spaced-paragraph roboto-font">Who says you can't have all three? By understanding your entire environment and the relationships within it, Magellan lets you achieve:</p>

        <p class="spaced-paragraph roboto-font indented"><strong class="color2">Growth:</strong> Identify and seize opportunities for scaling your operations. Magellan helps you pinpoint where to expand your services and optimize performance to support more customers effectively.</p>
        <p class="spaced-paragraph roboto-font indented"><strong class="color2">Lower Costs:</strong> Optimize resource usage and reduce expenses. By analyzing your cloud environment and applications, Magellan provides actionable insights to cut costs without compromising on performance.</p>
        <p class="spaced-paragraph roboto-font indented"><strong class="color2">Faster Performance:</strong> Enhance the customer experience by improving the efficiency and speed of your applications. Magellan identifies bottlenecks and offers solutions to improve response times and overall system performance.</p>

        <h2 class="color2 roboto-font">Magellan's Solution</h2>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Map Your Cloud Environment:</strong> Understand the relationship between services, infrastructure, and code. Magellan creates a comprehensive map of your cloud environment to visualize dependencies and interactions.</p>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Analyze Your Applications:</strong> Examine resource configuration, key activities, and supported language versions. This analysis helps you understand how your applications are set up and where improvements can be made.</p>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Understand Your Metrics:</strong> Combine real-time utilization metrics with a comprehensive knowledge base of industry best practices. Magellan ensures you have the latest and most relevant data to make informed decisions.</p>

        <h2 class="color2 roboto-font">Security and Scalability</h2>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Dedicated Customer Enclaves:</strong> All customer data is stored in dedicated enclaves (optionally hosted within customer environments) and secured by design, ensuring no data leaves the enclave.</p>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Scalable Views:</strong> For larger environments, views can be scoped down or aggregated across enclaves. This flexibility allows you to manage and monitor your environment efficiently, regardless of size.</p>

        <h2 class="color2 roboto-font">Focus on Execution</h2>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Concentrate on Action:</strong> Magellan lets your most impactful engineers focus on execution rather than investigation. By providing clear and actionable insights, Magellan reduces the time spent on diagnosing issues and planning interventions.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'EndUsersPage'
}
</script>

<style scoped>
.top-padding {
  padding-top: 100px;
}

.color2 {
  color: #ff9900;
}

.roboto-font {
  font-family: 'Roboto', sans-serif;
}

.spaced-paragraph {
  margin-bottom: 20px;
}

.indented {
  margin-left: 20px;
}

.bold-text {
  font-weight: bold;
  color: #ff9900;
}
</style>
