<template>
  <v-container class="top-padding">
    <v-row>
      <v-col cols="12">
        <h1 class="color2 roboto-font">Magellan for FinOps Professionals</h1>
        <p class="spaced-paragraph roboto-font">FinOps Evolution: FinOps has evolved significantly over time. In the early stages, FinOps 1.0 focused on specific tasks like optimizing AWS spot instances with tools such as SpotInst. As it progressed to FinOps 2.0, the emphasis shifted towards spend optimization, providing tools that could help manage costs but without the ability to guide purchasing decisions. Now, with FinOps 3.0, Magellan offers comprehensive insights that not only optimize spend but also provide recommendations on what should be purchased and predict costs with varying investment levels.</p>

        <h2 class="color2 roboto-font">Enhanced Visibility and Integration</h2>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Optimal Performance Visibility:</strong> Magellan allows visibility into how close to optimal your services and platforms are running.</p>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Transparency:</strong> Ensures that no issues are hidden or overlooked, providing a clear picture of your entire environment.</p>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Business Metrics Integration:</strong> Integrate with business metrics to benchmark performance and determine unit costs effectively.</p>

        <h2 class="color2 roboto-font">Delivering Comprehensive Value</h2>
        <p class="spaced-paragraph roboto-font">Magellan enables FinOps teams to not only deliver cost savings but also understand potential changes and how they can impact customer experience and overall scalability. This broader perspective allows FinOps professionals to make more informed decisions that align with both financial goals and operational excellence. Magellan provides insight into the efficiency level of cloud resources and detailed impact analysis for changes, helping you optimize resource usage and understand the effects of your decisions.</p>
        <p class="spaced-paragraph roboto-font">Magellan can model different levels of investment targeted to various outcomes, allowing you to see the potential impact and benefits of different spending strategies.</p>

        <h2 class="color2 roboto-font">Focus on Execution</h2>
        <p class="spaced-paragraph roboto-font"><strong class="color2">Partner with Engineering Teams:</strong> Magellan enables FinOps professionals to collaborate closely with engineering teams on the most impactful and actionable work. By providing clear and actionable insights, Magellan ensures that efforts are focused on the highest value tasks, streamlining the process and enhancing overall efficiency.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FinOpsPage'
}
</script>

<style scoped>
.top-padding {
  padding-top: 100px;
}

.color2 {
  color: #ff9900;
}

.roboto-font {
  font-family: 'Roboto', sans-serif;
}

.spaced-paragraph {
  margin-bottom: 20px;
}

.indented {
  margin-left: 20px;
}

.bold-text {
  font-weight: bold;
  color: #ff9900;
}
</style>
