<template>
  <v-app>
    <Navbar />
    <router-view />
  </v-app>
</template>

<script>
import Navbar from './components/AppNavbar.vue';

export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style>
.top-padding {
  padding-top: 100px;
}
</style>