import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AboutPage from '../views/AboutPage.vue';
import SolutionsPage from '../views/SolutionsPage.vue';
import OpensourcePage from '../views/OpensourcePage.vue';
import EndUsersPage from '../views/EndUsersPage.vue';
import CloudAdvisorsPage from '../views/CloudAdvisorsPage.vue';
import ProductPage from '../views/ProductPage.vue';
import FinOpsPage from '../views/FinOpsPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/solutions',
    name: 'SolutionsPage',
    component: SolutionsPage
  },
  {
    path: '/opensource',
    name: 'OpensourcePage',
    component: OpensourcePage
  },
  {
    path: '/endusers',
    name: 'EndUsersPage',
    component: EndUsersPage
  },
  {
    path: '/cloud-advisors',
    name: 'CloudAdvisorsPage',
    component: CloudAdvisorsPage
  },
  {
    path: '/product',
    name: 'ProductPage',
    component: ProductPage
  },
  {
    path: '/finops',
    name: 'FinOpsPage',
    component: FinOpsPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
