<template>
  <v-container class="top-padding">
    <v-row>
      <v-col cols="12">
        <h1 class="color1 roboto-font">Magellan OSS</h1>
      </v-col>
      <v-col cols="12">
        <v-card class="summary-card">
          <v-card-text>
            <p class="project-text roboto-font">
              We stand on the shoulders of giants. What we can create at Magellan is due to the thousands of people who came before us and gifted the world with their incredible work. This is our small way of continuing their legacy.
            </p>
          </v-card-text>
        </v-card>
        <v-card class="mt-5 project-card">
          <v-card-title>
            <h3 class="roboto-font">Astrolabe</h3>
          </v-card-title>
          <v-card-subtitle>
            <p class="roboto-font"><strong>Initial release expected late summer 2024</strong></p>
            <p class="roboto-font">Based on itsy-bitsy originally developed at Life360</p>
          </v-card-subtitle>
          <v-card-text>
            <p class="project-text roboto-font">
              Astrolabe is the open-source cloud mapping tool that uses Neo4j to allow users, ranging from small teams to large enterprises, to understand the complex interactions of their cloud infrastructure. Designed with flexibility in mind, Astrolabe's plugin style architecture empowers users to define resource discovery strategies that suit their unique needs, whether that involves runtime introspection of servers, industry standard datasources like Cloud Custodian, or custom site-specific datasources. All of the complex relationships between services, servers, and resources are then stored in Neo4j allowing users to take a complicated cloud infrastructure and transform it into a visual and queryable map.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OpensourcePage'
}
</script>

<style scoped>
.top-padding {
  padding-top: 100px;
}

.color1 {
  color: #f29716;
}

.roboto-font {
  font-family: 'Roboto', sans-serif;
}

.summary-card {
  margin-bottom: 20px;
}

.project-card {
  margin-top: 20px;
}

.project-text {
  font-family: 'Roboto', sans-serif;
  color: #333;
}
</style>
