<template>
  <v-container>
    <h1 class="color1">Solutions</h1>
    <p class="color2">This page contains information about our solutions.</p>
  </v-container>
</template>

<script>
export default {
  name: 'Solutions'
}
</script>
